/* You can add global styles to this file, and also import other style files */
@import url('./assets/fonts/fonts.css')
@import 'assets/sass/inputs-material'

body
    margin: 0
    font-family: hn-heavy

.hidden
    visibility: hidden

.custom-scroll::-webkit-scrollbar-track
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  border-radius: 8px
  background-color: #F5F5F5

.custom-scroll::-webkit-scrollbar
  width: 5px
  background-color: #F5F5F5

.custom-scroll::-webkit-scrollbar-thumb
  border-radius: 8px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  background-color: #555