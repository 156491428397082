@import 'color'

mat-label
    color: #8e8e8ee2

.mat-form-field-wrapper
    padding-bottom: 0

.mat-form-field-appearance-outline .mat-form-field-infix
    border-top: 0

.mat-form-field-appearance-outline .mat-select-arrow-wrapper
    transform: translateY(0) !important

.mat-select-arrow
    color: $color-background-button

.mat-form-field
    width: 100%

.mat-form-field-appearance-outline .mat-form-field-label
    margin-top: 0
    top: 2em

.mat-chip-list-wrapper
    display: flex
    height: auto
    line-height: 0 !important
    margin: 0 !important
    max-height: 100%
    width: 100%