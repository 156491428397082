@font-face {
    font-family: 'hn-heavy';
    src: url('Helvetica Neue LT Std 87 Heavy Condensed.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'hn-heavy-lite';
    src: url('Helvetica Neue LT Std 87 Heavy Condensed.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }